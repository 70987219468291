import React, { useEffect, useRef, useState } from 'react'
import { getSanity } from '../lib/api'
import { urlForImage } from '../lib/urlForImage.js'
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { register } from 'swiper/element/bundle'
register()

const Carousel = ({ title }) => {
	const [images, setImages] = useState([])
	const [isInitialized, setIsInitialized] = useState(false)
	// const [isVisible, setIsVisible] = useState(false)
	const swiperRef = useRef(null)

	useEffect(() => {
		// Define and add keyframes to the document stylesheet
		const styleSheet = document.styleSheets[0]

		const keyframesLeft = `
			@keyframes moving-arrow-left {
				0% { left: 0; opacity: 1; }
				40% { left: 0; opacity: 1; }
				46.6% { left: 16px; opacity: 0; }
				53.3% { left: -16px; opacity: 0; }
				60% { left: 0; opacity: 1; }
				100% { left: 0; opacity: 1; }
			}
		`
		const keyframesRight = `
			@keyframes moving-arrow-right {
				0% { right: 0; opacity: 1; }
				40% { right: 0; opacity: 1; }
				46.6% { right: -16px; opacity: 0; }
				53.3% { right: 16px; opacity: 0; }
				60% { right: 0; opacity: 1; }
				100% { right: 0; opacity: 1; }
			}
		`

		styleSheet.insertRule(keyframesLeft, styleSheet.cssRules.length)
		styleSheet.insertRule(keyframesRight, styleSheet.cssRules.length)

		setIsInitialized(true)

		// Fetch data from Sanity
		const fetchPortfolioItems = async () => {
			try {
				const query = `*[_type == "portfolio"] | order(orderRank asc) {_id, slug, image, isFeatured}`
				const portfolio = await getSanity(query)
				setImages(portfolio)
			} catch (error) {
				console.error('Failed to fetch data from Sanity:', error)
			}
		}
		fetchPortfolioItems()
	}, [])

	useEffect(() => {
		if (swiperRef.current) {
			const swiperInstance = new Swiper(swiperRef.current, {
				modules: [Navigation, Pagination],
				navigation: {
					nextEl: '.button-next',
					prevEl: '.button-prev'
				},
				lazy: true,
				slidesPerView: 1,
				spaceBetween: 24,
				// loop: true,
				preventInteractionOnTransition: true,
				breakpoints: {
					768: {
						slidesPerView: 1.1
					},
					1024: {
						slidesPerView: 1.2
					}
				},
				speed: 800
				// autoplay: {
				// 	delay: 4000, // 4 seconds delay
				// 	disableOnInteraction: false // Continue autoplay after interactions
				// }
			})

			const resizeHandler = () => {
				if (swiperInstance) {
					swiperInstance.update()
				}
			}

			window.addEventListener('resize', resizeHandler)

			return () => {
				window.removeEventListener('resize', resizeHandler)
			}
		}
	}, [images])

	return (
		<section className="animate-react flex flex-col py-16 md:flex-row">
			<div className="relative px-6 pb-8">
				{title && <h2 className="animate text-4xl md:w-[28vw]">{title}</h2>}
			</div>
			<div
				className={`swiper-container ${isInitialized ? 'fade-in' : 'invisible'} relative flex flex-col overflow-hidden px-6 md:px-0`}
				ref={swiperRef}
			>
				<div className="swiper-wrapper">
					{images.map(
						(item, index) =>
							item.isFeatured && (
								<div className="swiper-slide" key={index}>
									<div className="aspect-h-12 aspect-w-16">
										<img
											src={urlForImage(item.image.asset).auto('format').url()}
											alt={item.image.alt || 'Portfolio Image'}
											className="h-full w-full rounded-sm object-cover pb-6 brightness-70"
										/>
										<a href={`/portfolio/${item.slug.current}`} className="group">
											<div
												className={`absolute bottom-6 left-0 right-0 z-10 w-full font-serif text-2xl text-stone-50`}
											>
												<div className="m-6 grid grid-cols-4 justify-between gap-6">
													<div className="col-span-3 group-hover:font-italic">
														{item.image.caption}
													</div>
													<div className="col-span-1 mb-[2px] flex h-6 w-6 items-center justify-center place-self-end rounded-full border border-stone-50 fill-stone-50 text-stone-50 transition-all duration-500 group-hover:bg-stone-50 group-hover:fill-aztec-950 group-hover:text-aztec-950">
														<div className="h-1/2 w-1/2">
															<svg viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
																<path d="M515 1955l930-931L515 93l90-90 1022 1021L605 2045z" />
															</svg>
														</div>
													</div>
												</div>
											</div>
										</a>
									</div>
								</div>
							)
					)}
				</div>
				<div className="flex justify-center gap-6 py-2 md:justify-end md:px-6 md:py-6">
					<button
						className="button-prev group flex h-10 w-10 rotate-180 items-center justify-center rounded-full border border-aztec-950"
						aria-label="Previous image"
						style={{ overflow: 'hidden', position: 'relative' }}
					>
						<svg
							viewBox="0 0 2048 2048"
							xmlns="http://www.w3.org/2000/svg"
							className="fill-aqua-800 stroke-aqua-800 w-4 stroke-2 group-hover:animate-[moving-arrow-right_1.5s]"
							style={{ position: 'relative' }}
						>
							<path d="M515 1955l930-931L515 93l90-90 1022 1021L605 2045z" />
						</svg>
					</button>
					<button
						className="button-next group flex h-10 w-10 items-center justify-center rounded-full border border-aztec-950"
						aria-label="Next image"
						style={{ overflow: 'hidden', position: 'relative' }}
					>
						<svg
							viewBox="0 0 2048 2048"
							xmlns="http://www.w3.org/2000/svg"
							className="fill-aqua-800 stroke-aqua-800 w-4 stroke-2 group-hover:animate-[moving-arrow-left_1.5s]"
							style={{ position: 'relative' }}
						>
							<path d="M515 1955l930-931L515 93l90-90 1022 1021L605 2045z" />
						</svg>
					</button>
				</div>
			</div>
		</section>
	)
}

export default Carousel
